// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Posts.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Posts.tsx");
  import.meta.hot.lastModified = "1734440168065.194";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { projectId, dataset } from "@/sanity/projectDetails";
import imageUrlBuilder from "@sanity/image-url";
const builder = imageUrlBuilder({
  projectId,
  dataset
});
export default function Posts({
  posts
}) {
  return <div className="grid col-span-2 md:grid-cols-2 grid-cols-1 gap-5 mt-24">
      {posts?.length > 0 ? posts.map(post => <Card key={post._id} className="h-[600px] xl:h-[450px] overflow-hidden relative">
            <CardHeader>
              {post.mainImage ? <div className="mb-3">
                  <img className="rounded-lg" src={builder.image(post.mainImage).width(1280).height(700).quality(80).url()} width={1280} height={700} alt={post.title} />
                </div> : null}
              <CardTitle>{post.title}</CardTitle>
              {/* <p>{new Date(post.publishedAt).toLocaleDateString()}</p> */}
            </CardHeader>
            <CardContent className="">
              <div className="">{post.bodyText}</div>
            </CardContent>
            <CardFooter className="absolute bottom-0 z-10 w-full pt-2 text-emerald-700 text-xl font-bold">
              <Link to={`/innlegg/${post.slug.current}`} className="expand-border">
                Les mer{" "}
              </Link>
            </CardFooter>
            <span className="bg-gradient-to-b from-[#ffffff00] via-[#fffffff0] to-[#ffffff] absolute w-full bottom-0 h-28"></span>
          </Card>) : <div className="p-4 text-red-500">No posts found</div>}
    </div>;
}
_c = Posts;
var _c;
$RefreshReg$(_c, "Posts");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;